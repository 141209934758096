var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-20 rounded bg-white"},[_c('div',{staticClass:"relative"},[_c('div',{staticClass:"mb-20 text-left px-20"},[(_vm.detail.status !== -1)?_c('a-steps',{attrs:{"current":_vm.current,"status":_vm.detail.status === 99 ? 'error' : 'process'}},[_c('a-step',{attrs:{"title":_vm.detail.status === 99 ? '审核失败' : '需求审核中'}}),_c('a-step',{attrs:{"title":"匹配服务商"}}),_c('a-step',{attrs:{"title":"评估服务商"}}),_c('a-step',{attrs:{"title":"已完成对接"}})],1):_c('div',{staticClass:"text-right"},[_c('a-space',[_c('status-box',{attrs:{"slot":"status","evaluate":_vm.detail.evaluate,"status":_vm.detail.status},slot:"status"},[(_vm.detail.status === 1)?_c('span',[_vm._v("待审核")]):_vm._e(),(_vm.detail.status === 10)?_c('span',[_vm._v("专家审核中")]):_vm._e(),(_vm.detail.status === 20)?_c('span',[_vm._v("管理员审核中")]):_vm._e(),(_vm.detail.status === 30 && _vm.detail.evaluate === 0)?_c('span',[_vm._v(" 匹配中 ")]):_vm._e(),(_vm.detail.status === 30 && _vm.detail.evaluate === 1)?_c('span',[_vm._v(" 待评估 ")]):_vm._e(),(_vm.detail.status === 30 && _vm.detail.evaluate === 2)?_c('span',[_vm._v(" 已完成 ")]):_vm._e(),(_vm.detail.status === 99)?_c('span',[_vm._v("审核失败")]):_vm._e(),(_vm.detail.status === -1)?_c('span',[_vm._v("已关闭")]):_vm._e()])],1)],1)],1),(_vm.updateFlag)?_c('div',[_c('ApplyForm',{attrs:{"dataSource":_vm.detail,"disabled":_vm.detail.status !== 99},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return _c('a-space',{attrs:{"size":20}},[_c('a-button',{on:{"click":function($event){return _vm.update(false)}}},[_vm._v("取消")]),_c('a-button',{attrs:{"size":"large","type":"primary"},on:{"click":function($event){return _vm.submit(validate)}}},[_vm._v(" 提交 ")])],1)}}],null,false,4282420533)})],1):_c('div',[_c('ApplyDisplay',{attrs:{"dataSource":_vm.detail,"supplys":_vm.supplys,"allowChecked":_vm.detail.evaluate === 1},on:{"change":_vm.selectCompany}}),(_vm.detail.evaluate)?_c('div',{staticClass:"mb-40"},[_c('gf-title',{staticClass:"mb-20"},[_vm._v("评选结果")]),(_vm.detail.evaluate === 1)?_c('div',[_c('file-upload',{attrs:{"multiple":false,"size":20,"accept":".pdf,.docx,.doc,.png,.jpg,.jpeg"},model:{value:(_vm.attachments),callback:function ($$v) {_vm.attachments=$$v},expression:"attachments"}})],1):_vm._e(),(_vm.detail.evaluate === 2)?_c('div',{staticClass:"flex items-center"},[_c('a-icon',{staticClass:"mr-10",attrs:{"type":"link"}}),_c('a',{staticClass:"hover:text-primary",attrs:{"href":_vm.detail.result.url,"target":"blank"}},[_vm._v(_vm._s(_vm.detail.result.Name))])],1):_vm._e()],1):_vm._e(),(_vm.detail.status > 0)?_c('div',{staticClass:"text-center"},[_c('a-space',{attrs:{"size":20}},[(!_vm.detail.evaluate)?_c('a-button',{attrs:{"type":"danger"},on:{"click":_vm.close}},[_vm._v("关闭需求")]):_vm._e(),(
              !_vm.detail.evaluate &&
              !_vm.detail.count &&
              (_vm.detail.status === 30 || _vm.detail.status === 99)
            )?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.update(true)}}},[_vm._v("重新编辑")]):_vm._e(),(
              _vm.detail.status === 30 && _vm.detail.count && _vm.detail.evaluate === 0
            )?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.evaluateRequirement(_vm.detail.id)}}},[_vm._v("发起评估")]):_vm._e(),(
              _vm.detail.status === 30 && _vm.detail.count && _vm.detail.evaluate === 1
            )?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.finishEvaluate(_vm.detail.id)}}},[_vm._v("完成评估")]):_vm._e(),(_vm.detail.evaluate === 2 && _vm.reviewFlag)?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.openReview}},[_vm._v("去评价")]):_vm._e()],1)],1):_vm._e()],1)]),_c('review-modal',{on:{"confirm":_vm.review},model:{value:(_vm.reviewVisible),callback:function ($$v) {_vm.reviewVisible=$$v},expression:"reviewVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }